<div
  (resized)="onResize()"
  class="chart-container"
>
  <ngx-charts-heat-map
    [results]="ngxChart.getConvertedMultiDataSet()"
    [scheme]="colorScheme"
    [legend]="showLegend"
    [yAxisLabel]="ngxChart.labels.secondGroupBy || yAxisLabel"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showYAxisLabel]="!!yAxisLabel"
    [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
    [animations]="true"
    [colorMappingFn]="colorMappingFn"
    (select)="onSelect($event)"
  >
    <ng-template
      #tooltipTemplate
      let-model="model"
    >
      @if (model) {
        <ng-container
          [ngTemplateOutlet]="stackedTooltip"
          [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
        />
      }
    </ng-template>
    <ng-template
      #seriesTooltipTemplate
      let-model="model"
    >
      @if (model) {
        <ng-container
          [ngTemplateOutlet]="seriesTooltip"
          [ngTemplateOutletContext]="{ usingFakeGroupBy: ngxChart.usingFakeGroupBy, tooltipItems: model }"
        />
      }
    </ng-template>
  </ngx-charts-heat-map>
</div>
